import React from 'react';

export default function App() {
  return (
    <div style={{ "padding": "30px" }}>
      <strong>Privacy Statement</strong>
      <br />
      <br />
      <p>
        At yozorasoft, we understand the importance of protecting your personal information and privacy. This privacy statement outlines how we collect, use, and protect your personal information.
        <br />
        <br />
        Information Collection and Use
        <br />

        We collect personal information when you register for our services or games. This may include your name, email address, and payment information. We use this information to provide our services and games to you, and to communicate with you about updates, changes, and other relevant information.
        <br />
        <br />

        We may also collect non-personal information, such as your IP address, browser type, and device information. This information is used to improve our services and games, and to analyze usage patterns.
        <br />
        <br />

        Information Sharing and Disclosure
        <br />

        We do not share your personal information with third parties, except as necessary to provide our services and games to you. We may share your information with our trusted partners who assist us in delivering our services and games, such as payment processors and hosting providers.
        <br />
        <br />

        We may also disclose your personal information to comply with legal requirements, enforce our policies, or protect the rights, property, or safety of ourselves or others.
        <br />
        <br />

        Data Security
        <br />

        We use industry-standard security measures to protect your personal information from unauthorized access, disclosure, and alteration. However, no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.
        <br />
        <br />

        Your Choices
        <br />

        You have the right to access, update, and delete your personal information at any time. You can also unsubscribe from our communications by following the instructions provided in our emails.
        <br />
        <br />

        Changes to this Privacy Statement
        <br />

        We may update this privacy statement from time to time to reflect changes in our services or legal requirements. We will notify you of any material changes by email or through our website.
      </p>
    </div>
  );
}