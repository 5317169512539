import React, { useEffect, useState } from 'react';
import { MDBBtn, MDBTable, MDBTableBody, MDBTableHead } from 'mdb-react-ui-kit';
import { MDBFileUpload } from 'mdb-react-file-upload';
import API from '../../utils/api';

export default function LoraUpload(props) {
  const [loras, setLoras] = React.useState([]);
  const [file, setFile] = useState(null);
  const [triggerwords, setTriggerWords] = useState("");

  function RefreshTable() {
    (async () => {
      try {
        let r = await API.get('/api/workerjob/mylora');
        setLoras(r);
      } catch (e) {
        console.log(e);
      }
    })();
  }

  useEffect(() => {
    RefreshTable();
  }, []);

  useEffect(() => {
    if (file == null) {
      setTriggerWords("no file selected");
      return;
    }

    //get filename
    let filename = file.name;
    if (!filename.endsWith(".safetensors")) {
      setTriggerWords("not a safetensors file");
      alert("Please select a safetensors file", "danger");
      return;
    }

    //check file name should not contain symbols except . _ -
    if (!/^[a-zA-Z0-9\.\-_ ]+$/.test(filename)) {
      setTriggerWords("file name should not contain symbols except . _ -");
      alert("File name should not contain symbols except . _ -", "danger");
      return;
    }

    //get trigger words
    filename = filename.replace(".safetensors", "");
    let splits = filename.split(" ");
    setTriggerWords(splits.join(", "));
  }, [file]);

  function Upload() {
    if (file == null) {
      alert("Please select a file", "danger");
      return;
    }

    let filename = file.name;
    if (!filename.endsWith(".safetensors")) {
      alert("Please select a safetensors file", "danger");
      return;
    }
    
    //check file name should not contain symbols except . _ -
    if (!/^[a-zA-Z0-9\.\-_ ]+$/.test(filename)) {
      setTriggerWords("file name should not contain symbols except . _ -");
      alert("File name should not contain symbols except . _ -", "danger");
      return;
    }

    document.getElementById("uploadbtn").disabled = true;

    alert("Uploading... Please be patient", "info");
    (async () => {
      try {
        let r = await API.upload('/api/workerjob/uploadlora', file);
        alert("Uploaded", "success");
      } catch (e) {
        alert(e, "danger");
        console.log(e);
      }

      document.getElementById("uploadbtn").disabled = false;

      RefreshTable();
      window.UpdateCredits();
    }
    )();
  }

  return (
    <>
      <h3>My LoRA</h3>
      <p>Cost: 200 credits each, will be kept for 90 days.<br />
        Uploading may take time.<br />
        You can use &lt;lora:&#91;LoRA file name without .safetensors&#93;:&#91;weight&#93;&gt;, e.g. &lt;lora:my character or style:1&gt;<br />
        The words in the file name will be used as trigger words.<br />
        The file name and trigger words are case-sensitive.<br />
        Some LoRAs may not work properly with some models and high resolution.<br />
        New LoRA may take a few minutes to take effect.<br />
        File name should contain only letters, numbers, spaces, ., -, and _.</p>
      <p style={{ color: "red" }}>
        LoRAs will be accessible for all users.<br />
        If you don't want to share, use hard-to-guess file name.
      </p>
      <div>
        <p>LoRA file (SD 1.5 compatible safetensors only, max 200MB)</p>
        <MDBFileUpload id='fileupload' label='Upload' getInputFiles={(files) => {
          console.log("Selected files:", files);
          setFile(files[0]);
        }} />
      </div>
      <br />
      <p>Trigger words: {triggerwords}</p>
      <MDBBtn id="uploadbtn" onClick={Upload}>Upload</MDBBtn>

      <br /><hr /><br />
      <MDBTable>
        <MDBTableHead>
          <tr>
            <th scope='col'>LoRA</th>
            <th scope='col'>Upload Time</th>
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          {
            loras.map(({
              lora,
              time
            }) =>
              <tr key={lora}>
                <td>{lora}</td>
                <td>{time}</td>
              </tr>
            )
          }
        </MDBTableBody>
      </MDBTable>
    </>
  )
}