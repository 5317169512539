import React, { useEffect } from 'react';

export default function App() {
  const data = [
    {
      model: "Japanese Female 1",
      src: "/audio/a1.mp3"
    },
    {
      model: "Japanese Female 2",
      src: "/audio/a2.mp3"
    },
    {
      model: "Japanese Female 3",
      src: "/audio/a3.mp3"
    },
    {
      model: "Japanese Female 4",
      src: "/audio/a4.mp3"
    },
    {
      model: "Japanese Female 5",
      src: "/audio/a5.mp3"
    },
    {
      model: "Japanese Female 6",
      src: "/audio/a6.mp3"
    },
  ];

  return (
    <div style={{ padding: "10pt" }}>
      {
        data.map((item, index) =>
          <div key={index}>
            <p>Model: {item.model}</p>
            <audio controls src={item.src}></audio>
          </div>
        )
      }
    </div>
  );
}