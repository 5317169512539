import React, { useEffect } from 'react';
import env from '../utils/env';
import {
  MDBCarousel,
  MDBCarouselItem,
} from 'mdb-react-ui-kit';

export default function App() {
  useEffect(() => {
    //jump to index
    window.location.href = "/";
  }, []);

  return (
    <>
    </>
  );
}