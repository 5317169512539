import React, { useEffect, useState } from 'react';
import API from '../../utils/api';
import { MDBBtn, MDBSelect, MDBTextArea, MDBRange, MDBInput, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import WorkerJobTable from '../../components/workerjobtable';

export default function App() {
    const pricePer1000Char = 5.0;

    const [text, setText] = useState("");
    const [voice, setVoice] = useState("");
    const [cost, setCost] = useState(0);

    const [tabledata, setTableData] = useState([]);

    useEffect(() => {
        updateCost();
    }, [text]);

    function updateCost() {
        setText(document.getElementById("text").value);
        //get first lang section
        let calccost = parseInt(pricePer1000Char * text.length / 1000) + 1;
        setCost(calccost);
    }

    useEffect(() => {
        //set table data row defaultSelected
        setTableData(tabledata.map(x => {
            if (x.value == voice) {
                x.defaultSelected = true;
            } else {
                x.defaultSelected = false;
            }
            return x;
        }));
    }, [voice]);

    useEffect(() => {
        (async () => {
            try {
                let r = await API.get('/api/workerjob/table/tts');
                //r is [text]
                r = r.map(x => { return { text: x, value: x } });
                //set first defaultSelected
                r[0].defaultSelected = true;
                setVoice(r[0].value);
                setTableData(r)
            } catch (e) {
                alert(e, "danger");
                console.log(e);
            }
        })();

        updateCost();
    }, []);

    function CreateTask() {
        (async () => {
            try {
                let r = await API.post('/api/workerjob/tts', { text: text, voicename: voice });
                let buf = r.audio.data;
                let blob = new Blob([new Uint8Array(buf)], { type: 'audio/mpeg' });
                //download
                let a = document.createElement('a');
                a.href = window.URL.createObjectURL(blob);
                a.download = 'tts.mp3';
                a.click();
                setTimeout(() => {
                    //remove element
                    window.URL.revokeObjectURL(a.href);
                    a.remove();
                }, 500);
            } catch (e) {
                alert(e, "danger");
                console.log(e);
            }

            window.UpdateCredits();
        })();
    }
    return (
        <>
            <h3>Text to Speech</h3>
            <div>
                <div>
                    <br />
                    <p>Text</p>
                    <MDBTextArea id={"text"} value={text} rows={3} onChange={
                        (e) => {
                            setText(e.target.value);
                        }
                    } />
                    <br />
                    <MDBSelect
                        id={"voice"}
                        label='Voice'
                        data={tabledata}
                        visibleOptions={10}
                        onValueChange={(e) => {
                            setVoice(e.value);
                        }}
                    />
                    <a href="/ttssample" target="_blank">See Samples</a>
                    <br />
                    <p>Cost: {cost} credits<br />The audio file will start to download automatically soon after pressing GENERATE button</p>
                    <MDBBtn id="createbtn" onClick={CreateTask}>Generate</MDBBtn>
                </div>
            </div>
        </>
    );
}