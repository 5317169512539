import React, { useEffect } from 'react';
import API from '../utils/api';
import {
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBSelect,
  MDBTextArea,
  MDBIcon
} from 'mdb-react-ui-kit';

export default function App() {
  const [category, setCategory] = React.useState('account');
  const [btntext, setBtntext] = React.useState('Submit');

  function Send() {
    document.getElementById("sendbtn").disabled = true;
    (async () => {
      try {
        //remove all ids
        let r = await API.post('/api/user/support', {
          name: document.getElementById("name").value,
          company: document.getElementById("company").value,
          email: document.getElementById("email1").value,
          category: category,
          info: document.getElementById("info").value
        });
        alert("Sent", "success");
        setBtntext("Sent");
      } catch (e) {
        document.getElementById("sendbtn").disabled = false;

        alert(e, "danger");
        console.log(e);
      }
    })();
  }

  return (
    <>
      <div style={{
        width: "100%",
      }}>
        <br />
        <div style={{ maxWidth: "600pt", margin: "auto", padding: "20pt" }}>
          <h1>Leave us a message</h1>
          <br /><br />
          <MDBRow className='mb-4'>
            <MDBCol>
              <MDBInput id='name' label='Name' />
            </MDBCol>
            <MDBCol>
              <MDBInput id='company' label='Company' />
            </MDBCol>
          </MDBRow>

          <MDBInput wrapperClass='mb-4' type='email' id='email1' label='E-mail' />
          <MDBSelect
            id={"category"}
            label='Category'
            data={[
              { text: 'Account', value: 'account', defaultSelected: category === 'account' },
              { text: 'Payment & Transactions (Provide payment/transaction id below)', value: 'payment', defaultSelected: category === 'payment' },
              { text: 'Feature Wish', value: 'wish', defaultSelected: category === 'wish' },
              { text: 'Other', value: 'other', defaultSelected: category === 'other' },
            ]}
            visibleOptions={10}
            onValueChange={(e) => {
              setCategory(e.value);
            }}
          />
          <br />
          <MDBTextArea wrapperClass='mb-4' id='info' rows={4} label='Additional information' />

          <MDBBtn id="sendbtn" className='mb-4' type='submit' block onClick={Send}>
            {btntext}
          </MDBBtn>

          <hr />
          <br />
          <div style={{
            width: "100%",
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
            <MDBBtn onClick={() => {
              window.open("https://twitter.com/yozoraworks");
            }} floating size='lg' tag='a'>
              <MDBIcon fab icon='twitter' />
            </MDBBtn>
            &nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;
            <MDBBtn onClick={() => {
              window.open("https://discord.gg/AVr6UEYQa3");
            }} floating size='lg' tag='a'>
              <MDBIcon fab icon='discord' />
            </MDBBtn>
          </div>
        </div>
      </div>
    </>
  );
}