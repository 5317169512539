import React, { useEffect } from 'react';

export default function App() {
  const data = [
    {
      model: "English Female",
      src: "/audio/1.mp3"
    },
    {
      model: "English Female Child",
      src: "/audio/3.mp3"
    },
    {
      model: "English Male",
      src: "/audio/2.mp3"
    },
    {
      model: "Chinese (Mandarin) Female",
      src: "/audio/4.mp3"
    },
    {
      model: "Chinese (Mandarin) Female Child",
      src: "/audio/6.mp3"
    },
    {
      model: "Chinese (Mandarin) Male",
      src: "/audio/5.mp3"
    },
    {
      model: "Japanese Female",
      src: "/audio/8.mp3"
    },
    {
      model: "Japanese Female 2",
      src: "/audio/9.mp3"
    },
    {
      model: "Japanese Female Child",
      src: "/audio/7.mp3"
    },
    {
      model: "Japanese Male",
      src: "/audio/10.mp3"
    },
    {
      model: "Japanese Male 2",
      src: "/audio/11.mp3"
    },
    {
      model: "Japanese Male 3",
      src: "/audio/12.mp3"
    },
  ];

  return (
    <div style={{ padding: "10pt" }}>
      {
        data.map((item, index) =>
          <div key={index}>
            <p>Model: {item.model}</p>
            <audio controls src={item.src}></audio>
          </div>
        )
      }
    </div>
  );
}