import React, { useEffect, useState } from 'react';
import API from '../utils/api';
import { MDBChart, MDBTable, MDBTableBody, MDBTableHead } from 'mdb-react-ui-kit';

export default function App() {
    const [jobs, setJobs] = useState([]);
    const [users, setUsers] = useState(0);
    const [visits, setVisits] = useState([]);
    const [workerinfo, setWorkerinfo] = useState("");

    useEffect(() => {
        (async () => {
            try {
                let r = await API.get('/api/workerjob/stat');
                setJobs(r.jobs);
                setWorkerinfo(r.workerinfo);
            } catch (e) {
                //jump to index
                window.location.href = "/";
            }
        })();

        (async () => {
            try {
                let r = await API.get('/api/user/stat/visits');
                setVisits(r.visits);
            } catch (e) {
                //jump to index
                window.location.href = "/";
            }
        })();

        (async () => {
            try {
                let r = await API.get('/api/user/stat/users');
                setUsers(r.users);
            } catch (e) {
                //jump to index
                window.location.href = "/";
            }
        })();
    }, []);

    return (
        <>
            <div style={{ padding: "16pt" }} hidden={users == 0}>
                <h5>{users}</h5>
                <p style={{ whiteSpace: "pre-wrap" }}>{workerinfo}</p>
                <hr />
                <h5>Visits</h5>
                <MDBChart
                    style={{ maxHeight: "300pt" }}
                    type='bar'
                    data={{
                        labels: visits.map((v) => v._id),
                        datasets: [
                            {
                                label: 'Visits',
                                data: visits.map((v) => v.count),
                            },
                        ],
                    }}
                />
                <hr />
                <h5>Jobs</h5>
                <MDBChart
                    style={{ maxHeight: "300pt" }}
                    type='bar'
                    data={{
                        labels: jobs.map((v) => v._id),
                        datasets: [
                            {
                                label: 'Jobs',
                                data: jobs.map((v) => v.count),
                            },
                        ],
                    }}
                />
            </div>
        </>
    );
}