import React from 'react';

export default function App() {
  return (
    //center of window
    <div style={{
      position: 'absolute', left: '50%', top: '50%',
      transform: 'translate(-50%, -50%)'
    }}>
      <div className='text-center'>
        <h1 className='display-4'>Payment Canceled</h1>
        <a href='/user'>Back to User Page</a>
      </div>
    </div>
  );
}