import React, { useEffect, useState } from 'react';
import API from '../utils/api';
import {
  MDBContainer,
  MDBCol,
  MDBLightbox,
  MDBLightboxItem,
  MDBRow,
} from 'mdb-react-ui-kit';

export default function Gallery(props) {
  const [files, setFiles] = useState([]);

  function Refresh() {
    (async () => {
      try {
        let r = await API.get('/api/workerjob/gallery' + (props.type ? props.type : ""));

        if (!props.type || props.type == "") {
          //shuffle
          for (let i = r.files.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [r.files[i], r.files[j]] = [r.files[j], r.files[i]];
          }
        }

        setFiles(r.files);
      } catch (e) {
        console.log(e);
      }
    })();
  }

  let updateinterval = props.updateinterval ? props.updateinterval : 0;

  useEffect(() => {
    Refresh();
    if (updateinterval > 0) {
      let interval = setInterval(() => {
        Refresh();
      }, updateinterval);
      return () => clearInterval(interval);
    }
  }, []);

  return (
    <div style={{ padding: "1.5pt" }}>
      {files.map(({ url, prompt }) =>
        <img
          key={url}
          src={url}
          style={{ padding: "3pt", height: "40vh", width: "auto", display: "inline" }}
          alt={prompt ? prompt : ""}
          title={prompt ? prompt : ""}
        />
      )}
    </div>
  );
}