import { useParams } from 'react-router-dom'
import React, { useEffect } from 'react';
import {
    MDBInput,
    MDBCol,
    MDBRow,
    MDBCheckbox,
    MDBBtn
} from 'mdb-react-ui-kit';
import API from '../../utils/api';

export default function App() {
    const { id } = useParams();

    function resetPassword(e) {
        e.preventDefault();

        const pass1 = document.getElementById('pass1').value;
        const pass2 = document.getElementById('pass2').value;

        //check complexity
        if (pass1.length < 8) {
            alert('Password must be at least 8 characters');
            return;
        }

        if (pass1 !== pass2) {
            alert('Passwords do not match');
            return;
        }

        (async () => {
            try {
                let r = await API.post('/api/user/changepassword', { password: pass1, token: id });
                alert('Password Changed', 'success');
            } catch (e) {
                console.log(e);
                alert(e, 'danger');
            }
        })();
    }

    return (
        <div style={{
            position: 'absolute', left: '50%', top: '50%',
            transform: 'translate(-50%, -50%)'
        }}>
            <form>
                <MDBInput className='mb-4' type='password' id='pass1' label='New Password' />
                <MDBInput className='mb-4' type='password' id='pass2' label='Confirm Password' />

                <MDBBtn type='submit' block onClick={resetPassword}>
                    Set Password
                </MDBBtn>
            </form>
        </div>
    );
}