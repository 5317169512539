import React, { useEffect, useState } from 'react';
import API from '../utils/api';
import { MDBBtn, MDBSelect, MDBTextArea, MDBRange, MDBInput, MDBRow, MDBCol, MDBCheckbox } from 'mdb-react-ui-kit';
import Gallery from '../components/gallery';

export default function App() {
    const [notAvailable, setNotAvailable] = useState(false);

    const [prompt, setPrompt] = useState("");
    const [used, setUsed] = useState(0);
    const [status, setStatus] = useState("");
    const [tabledata, setTableData] = useState([]);
    const [model, setModel] = useState("");

    const limit = 10;

    useEffect(() => {
        //set table data row defaultSelected
        setTableData(tabledata.map(x => {
            if (x.value == model) {
                x.defaultSelected = true;
            } else {
                x.defaultSelected = false;
            }
            return x;
        }));
    }, [model]);

    useEffect(() => {
        (async () => {
            try {
                let r = await API.get('/api/workerjob/table/freeimagecreation');
                //r is [text]
                r = r.map(x => { return { text: x, value: x } });
                //set first defaultSelected
                r[0].defaultSelected = true;
                setModel(r[0].value);
                setTableData(r)
            } catch (e) {
                alert(e, "danger");
                console.log(e);
            }
        })();

        (async () => {
            try {
                let r = await API.get('/api/user/country');
                let available = r.available;
                if (!available) {
                    setNotAvailable(true);
                }
            } catch (e) {
                setNotAvailable(true);
            }
        })();

        UpdateQuota();
    }, []);

    function UpdateQuota() {
        (async () => {
            try {
                let r = await API.get('/api/workerjob/ipfreeimagequota');
                setUsed(r.count);
                setStatus(r.status);
            } catch (e) {
                setUsed(0);
                console.log(e);
            }
        })();
    }

    //updat4 gallery every 5 seconds
    useEffect(() => {
        const interval = setInterval(() => {
            UpdateQuota();
        }, 5000);
        return () => clearInterval(interval);
    }, []);

    function CreateTaskH() {
        CreateTask("horizontal");
    }

    function CreateTaskV() {
        CreateTask("vertical");
    }

    function CreateTaskS() {
        CreateTask("square");
    }

    function CreateTask(orientation) {
        document.getElementById("createbtn1").disabled = true;
        document.getElementById("createbtn2").disabled = true;
        document.getElementById("createbtn3").disabled = true;

        (async () => {
            let data = {
                prompt: prompt,
                model: model,
                orientation: orientation
            };

            try {
                let r = await API.post('/api/workerjob/freeimage', data);
                console.log(r);
                alert("Task created");
            } catch (e) {
                alert(e, "danger");
                console.log(e);
            }

            document.getElementById("createbtn1").disabled = false;
            document.getElementById("createbtn2").disabled = false;
            document.getElementById("createbtn3").disabled = false;
            UpdateQuota();
        })();
    }

    //message: {role, content}
    //loop through messages and display them
    return (
        <div>
            <div style={{ maxWidth: "600pt", margin: "auto", padding: "20pt" }}>
                <h3>FREE Image Creation</h3>
                {notAvailable && <p style={{ color: "red" }}>This service is not available in your country.</p>}
                <div hidden={notAvailable}>
                    <p>Your requests will be queued and results will be shown below once complete.<br />
                        Images will be kept for 3 days.</p>
                    <p style={{ color: "blue" }}>Sign up for more functions, models, custom resolution, and your own LoRA.</p>
                    <p style={{ color: "red" }}>Disclaimer: This service uses third-party APIs and yozorasoft does not endorse any of them. This service does not represent our political and religional views, and may provide sensitive or NSFW content. You must be at least 18 years old when prompting NSFW instructions. Please use according to your local laws and regulations.</p>
                    <div>
                        <MDBTextArea id={"prompt"} value={prompt} rows={3} label="Prompt" onChange={
                            (e) => {
                                setPrompt(e.target.value);
                            }
                        } />
                        <br />
                        <MDBSelect
                            id={"model"}
                            label='Model'
                            data={tabledata}
                            visibleOptions={10}
                            onValueChange={(e) => {
                                setModel(e.value);
                            }}
                        />
                        <a href="/imagecreationsample" target="_blank">See Samples</a>
                        <p style={{ paddingTop: "8pt" }}>Free Limit: 1 queued work, shared server, {limit} images per IP address per week.<br />
                            If you move place or change network, you may lose your images.<br />
                            Used: {used}<br />
                            Current work status: {status}</p>
                        <h5>Select image orientation to start</h5>
                        <MDBRow>
                            <MDBCol size="4" lg="3">
                                <MDBBtn id="createbtn1" onClick={CreateTaskH} style={{ width: "100pt" }}>Horizontal</MDBBtn>
                            </MDBCol>
                            <MDBCol size="4" lg="3">
                                <MDBBtn id="createbtn3" onClick={CreateTaskS} style={{ width: "100pt" }}>Squared</MDBBtn>
                            </MDBCol>
                            <MDBCol size="4" lg="3">
                                <MDBBtn id="createbtn2" onClick={CreateTaskV} style={{ width: "100pt" }}>Vertical</MDBBtn>
                            </MDBCol>
                        </MDBRow>
                    </div>
                </div>
            </div>
            <hr />
            <Gallery type="free" updateinterval={5000} />
        </div >
    );
}