import React, { useEffect, useState } from 'react';
import {
  MDBInput,
  MDBCol,
  MDBRow,
  MDBCheckbox,
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from 'mdb-react-ui-kit';
import Web3 from './web3/web3';
import API from '../utils/api';
import ReCAPTCHA from "react-google-recaptcha";

export default function App({ buttonTitle = "Login", jumpto = null }) {
  const [basicModal, setBasicModal] = useState(false);
  const [login, setLogin] = useState(null);
  const recaptchaRef = React.createRef();

  window.recaptchaOptions = {
    useRecaptchaNet: true
  };

  let web3challenge = null;

  function web3callback(e) {
    if (e) {
      web3challenge = e;
      Login();
    }
  }

  const toggleShow = () => setBasicModal(!basicModal);
  window.showlogin = toggleShow;

  useEffect(() => {
    if (login) {
      if (jumpto) {
        window.location.replace(jumpto);
      } else {
        window.location.reload();
      }
    }
  }, [login]);

  function GetFormData() {
    const email = document.getElementById('email').value;
    const password = document.getElementById('password').value;
    const rememberme = document.getElementById('rememberme').checked;
    const challenge = web3challenge;

    return {
      email,
      password,
      rememberme,
      challenge,
      captcha
    };
  }

  let captcha = "";
  function Captcha(e) {
    console.log("captcha", e);
    captcha = e;
  }

  function Login(e) {
    e.preventDefault();

    if (!captcha || captcha == "") {
      alert('Please complete the reCaptcha', "danger");
      return;
    }

    //check email
    const email = document.getElementById('email').value;
    if (!email) {
      alert('Please enter your email address', "danger");
      return;
    }

    const password = document.getElementById('password').value;
    if (password.length < 8) {
      alert('Password must be at least 8 characters', "danger");
      return;
    }

    (async () => {
      try {
        let r = await API.post('/api/user/login', GetFormData());
        API.SetCookie('token', r.token, GetFormData().rememberme);
        recaptchaRef.current.reset();
        setLogin(true);
      } catch (e) {
        alert(e, "danger");
        recaptchaRef.current.reset();
        console.log(e);
      }
    })();
  }

  function Signup(e) {
    e.preventDefault();

    if (!captcha || captcha == "") {
      alert('Please complete the reCaptcha', "danger");
      return;
    }

    if (!document.getElementById('tos').checked) {
      alert('You must agree to the terms and conditions', "danger");
      return;
    }

    //check email
    const email = document.getElementById('email').value;
    if (!email) {
      alert('Please enter your e-mail address', "danger");
      return;
    }

    const password = document.getElementById('password').value;
    if (password.length < 8) {
      alert('Password must be at least 8 characters', "danger");
      return;
    }

    (async () => {
      try {
        let r = await API.post('/api/user/signup', GetFormData());
        if (r) {
          alert('Account created, a verification E-mail has been sent', "success");
          recaptchaRef.current.reset();
        }
        recaptchaRef.current.reset();
      } catch (e) {
        alert(e, "danger");
        recaptchaRef.current.reset();
        console.log(e);
      }
    })();
  }

  function resetPassword(e) {
    e.preventDefault();

    if (!captcha || captcha == "") {
      alert('Please complete the reCaptcha', "danger");
      return;
    }

    const email = document.getElementById('email').value;

    if (!email) {
      alert('Please enter your e-mail address', "danger");
      return;
    }

    (async () => {
      try {
        let r = await API.post('/api/user/resetpassword', { email });
        alert('Password reset e-mail sent', "success");
        recaptchaRef.current.reset();
      } catch (e) {
        alert(e, "danger");
        recaptchaRef.current.reset();
        console.log(e);
      }
    })();
  }

  return (
    <>
      <MDBBtn color='light' onClick={toggleShow}>{buttonTitle}</MDBBtn>
      <MDBModal show={basicModal} setShow={setBasicModal} tabIndex='-1' >
        <MDBModalDialog size="lg">
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Login / Sign Up</MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={toggleShow}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <>
                <MDBInput className='mb-4' type='email' id='email' label='E-mail address' />
                <MDBInput className='mb-4' type='password' id='password' label='Password' />
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey="6LeEBMolAAAAAKzlPUwvoxkGUjkiAD-oJlpsRfRC"
                  onChange={Captcha}
                  style={{
                    width: "100%",
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }} />
                <br />
                <MDBRow className='mb-4'>
                  <MDBCol className='d-flex justify-content-center'>
                    <MDBCheckbox id='rememberme' label='Remember me' defaultChecked />
                  </MDBCol>
                  <MDBCol>
                    <a href='/user/resetpassword' onClick={resetPassword}>Forgot password?</a>
                  </MDBCol>
                </MDBRow>

                <MDBBtn type='submit' onClick={Login} block>
                  Sign in
                </MDBBtn>
                <hr />
                <input type="checkbox" style={{ width: "30px" }} id="tos" value='' /> I agree to the <a href="/tos">Terms of Service</a> and <a href="/privacy">Privacy Policy</a>
                <br /><br />
                <MDBBtn type='submit' onClick={Signup} block>
                  Sign up
                </MDBBtn>
                <div hidden>
                  <hr />
                  <Web3 title="Web3 Login" action={{ name: "connect" }} callback={web3callback} />
                </div>
              </>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}