import { useParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react';
import API from '../../utils/api';

export default function App() {
    const { id } = useParams();

    const [message, setMessage] = useState("Verifying...");

    useEffect(() => {
        (async () => {
            try {
                let r = await API.post('/api/user/verifyemail', { token: id });
                setMessage("Verified");
            } catch (e) {
                setMessage(e);
            }
        })();
    }, []);

    return (
        <div style={{
            position: 'absolute', left: '50%', top: '50%',
            transform: 'translate(-50%, -50%)'
        }}>
            <div className='text-center'>
                <h1 className='display-1'>{message}</h1>
            </div>
        </div>
    );
}