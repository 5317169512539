import React from 'react';
import { MDBBtn, MDBTable, MDBTableBody, MDBTableHead } from 'mdb-react-ui-kit';
import API from '../utils/api';

export default function WorkerJobTable(props) {
  if (!props.jobs) {
    props.jobs = [];
  }

  const fileserver = "https://yozora-worker.s3-accelerate.amazonaws.com/";

  //s3
  function DownloadFile(file) {
    const link = fileserver + file;
    window.open(link, '_blank');
  }

  function IsImage(filename) {
    let lowercase = filename.toLowerCase();
    return lowercase.endsWith(".png") || lowercase.endsWith(".jpg") || lowercase.endsWith(".jpeg")
      || lowercase.endsWith(".gif") || lowercase.endsWith(".bmp") || lowercase.endsWith(".svg")
      || lowercase.endsWith(".webp");
  }

  function IsAudio(filename) {
    let lowercase = filename.toLowerCase();
    return lowercase.endsWith(".mp3") || lowercase.endsWith(".wav") || lowercase.endsWith(".ogg")
      || lowercase.endsWith(".wma") || lowercase.endsWith(".flac") || lowercase.endsWith(".aac");
  }

  return (
    <MDBTable style={{ fontSize: "10pt" }}>
      <MDBTableHead>
        <tr>
          <th scope='col'>Info</th>
          <th scope='col'>Input Files</th>
          <th scope='col'>Output Files</th>
          <th scope='col'>Status</th>
        </tr>
      </MDBTableHead>
      <MDBTableBody>
        {
          props.jobs.map(({
            id,
            type,
            userinput,
            inputfiles,
            outputfiles,
            status,
            created,
            finished,
          }) =>
            //title: role
            //content: content
            <tr key={id}>
              <td style={{ maxWidth: "200px" }}>ID {id}<br />{type}<br />{JSON.stringify(userinput, null, 2)}</td>
              <td>{
                inputfiles.map((file) =>
                  IsImage(file) ? <img src={fileserver + file} style={{ width: "80pt" }} /> : <div>
                    {IsAudio(file) && <audio controls src={fileserver + file} />}
                    <MDBBtn style={{ marginRight: "10px" }} key={file} onClick={() => DownloadFile(file)}>{file}</MDBBtn>
                  </div>
                )}
              </td>
              <td>{
                outputfiles.map((file) =>
                  IsImage(file) ? <img src={fileserver + file} style={{ width: "160pt" }} /> : <div>
                    {IsAudio(file) && <audio controls src={fileserver + file} />}
                    <MDBBtn style={{ marginRight: "10px" }} key={file} onClick={() => DownloadFile(file)}>{file}</MDBBtn>
                  </div>
                )}
              </td>
              <td>{status}<br />Create: {created}<br />Finish: {status == "success" ? finished : "N/A"}</td>
            </tr>
          )
        }

      </MDBTableBody>
    </MDBTable>
  )
}