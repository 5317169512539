var env = {}

env.ver = "04/25/2023";

env.domain = 'http://localhost:3000';

env.server = 'http://localhost';
env.domainname = "yozoraworks testing";

if (process.env.PUBLIC_URL) {
    env.domain = process.env.PUBLIC_URL;

    if (process.env.PUBLIC_URL.indexOf("stag") > -1) {
        env.server = 'http://stage.api.yozoraworks.com';
        env.domainname = "yozoraworks staging";
    } else {
        env.server = 'https://api.yozoraworks.com';
        env.domainname = "yozoraworks";
    }
}

module.exports = env;