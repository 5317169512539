import React, { useEffect, useState } from 'react';
import API from '../../utils/api';
import { MDBBtn, MDBTextArea } from 'mdb-react-ui-kit';

export default function App() {
    const [messages, setMessages] = useState([{ id: "system", role: "system", content: "You are ChatGPT to answer questions." }]);
    const [mymsg, setMyMsg] = useState("");

    function RandomString(len = 16) {
        var text = "";
        var charset = "abcdefghijklmnopqrstuvwxyz0123456789";
        for (var i = 0; i < len; i++)
            text += charset.charAt(Math.floor(Math.random() * charset.length));
        return text;
    }

    function SendMessages() {
        //document.getElementById("sendbtn").textContent = "Thinking...";
        let usermsg = { id: RandomString(10), role: "user", content: mymsg };
        let msgs = messages.map(x => { return { role: x.role, content: x.content } });
        msgs.push({ role: "user", content: mymsg });

        (async () => {
            alert("Thinking...", "info")

            try {
                //remove all ids
                let r = await API.post('/api/workerjob/gpt', { messages: msgs });
                console.log(r);
                let msg = r.message;
                msg.id = RandomString(10);

                //add to messages
                setMessages([...messages, usermsg, msg]);

                //document.getElementById("sendbtn").textContent = "Send Message";
                setMyMsg("");

                //scroll to bottom
                setTimeout(() => {
                    window.scrollTo(0, document.body.scrollHeight);
                }, 100);
            } catch (e) {
                //document.getElementById("sendbtn").textContent = "Send Message";

                alert(e, "danger");
                console.log(e);
            }

            window.UpdateCredits();
        })();
    }

    //message: {role, content}
    //loop through messages and display them
    return (
        <>
            <h3>GPT</h3>
            <p>
                Price: ~3 credits per 1000 words or non-English characters.<br />
                The words and characters are counted by adding all sections and the response together everytime you send a message<br />
                There is an overall limit of around 2000-3000 words or non-English characters.<br />
                Failed requests will not be charged.<br />
            </p>
            <p style={{ color: "red" }}>Disclaimer: This service uses third-party APIs and yozorasoft does not endorse any of them. This service does not represent our political and religional views, and may provide inaccurate information. Please use according to your local laws and regulations.</p>
            <div>
                {
                    messages.map(({ id, role, content }) =>
                        //hide system
                        role == "system" ? null :
                            //title: role
                            //content: content
                            <div key={id}>
                                <p style={{ color: role == "user" ? "green" : "orange" }}>{role.toUpperCase()}</p>
                                <p style={{whiteSpace: "pre-wrap"}}>{content}</p>
                            </div>
                    )
                }
            </div>


            <MDBTextArea value={mymsg} rows={3} onChange={(e) => {
                setMyMsg(e.target.value);
            }} />
            <MDBBtn id={"sendbtn"} color='primary' className='m-1' onClick={SendMessages}>Send Message</MDBBtn>
            <br /><br />
        </>
    );
}