import { Outlet } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import API from '../../utils/api';
import { MDBBtn, MDBContainer, MDBRow, MDBCol, MDBSideNavLink } from 'mdb-react-ui-kit';

var UserButton = (props) => {
    return (
        //fill horizontal
        <>
            <MDBSideNavLink href={props.path} onClick={props.onClick} style={{ "borderRadius": "0px" }}>
                {props.children}
            </MDBSideNavLink>
        </>
    );
}

export default function RouteUser() {
    const [credit, setCredit] = useState(0);

    window.UpdateCredits = () => {
        (async () => {
            try {
                let r = await API.get('/api/user/info');
                setCredit(r.credit);
            } catch (e) {
                console.log(e);
                alert(e, "danger");
                window.location.replace("/");
            }
        })();
    }

    useEffect(() => {
        window.UpdateCredits();
    }, []);

    return (
        //height: 100vh - topbar
        <MDBContainer style={{ "margin": "0px", maxWidth: "100vw" }}>
            <MDBRow>
                <MDBCol size='2' style={{ height: "100%", "backgroundColor": "#222", color: "white", padding: "0px", "height": "calc(100vh - 51px)" }}>
                    <UserButton path='/user'>General</UserButton>
                    <UserButton path='/user/simplegpt'>SimpleGPT</UserButton>
                    <UserButton path='/user/gpt'>FullGPT</UserButton>
                    <UserButton path='/user/tts'>Text to Speech</UserButton>
                    <UserButton path='/user/speechtospeech'>Voice Convert</UserButton>
                    <UserButton path="/user/imagecreation">Image Creation</UserButton>
                    <UserButton path="/user/enlarge">Denoise & Enlarge</UserButton>
                    <UserButton path='/user/addcredits'>Add Credits</UserButton>
                    <br />
                    <p style={{ marginLeft: "18pt", fontSize: "11pt", color: "#ccc" }}>Credits: {credit.toFixed(2)}</p>
                    <br />
                    <UserButton onClick={() => {
                        API.SetCookie('token', "", true);
                        API.SetCookie('token', "", false);
                    }} path='/'>Log Out</UserButton>
                </MDBCol>
                <MDBCol size='10' style={{ "padding": "26px", overflowY: "scroll", overflowX: "hidden", "height": "calc(100vh - 51px)" }}>
                    <Outlet />
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    );
}