import { useParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react';
import API from '../../utils/api';

import { MDBInput, MDBBtn, MDBTable, MDBTableBody, MDBTableHead } from 'mdb-react-ui-kit';

export default function App() {
    const [credits, setCredits] = useState(1000);
    const [payments, setPayments] = useState([]);
    const [creditHistory, setCreditHistory] = useState([]);

    useEffect(() => {
        (async () => {
            try {
                let r = await API.get('/api/payment/history');
                if (!r)
                    r = [];
                setPayments(r);
            } catch (e) {
                console.log(e);
            }
        })();

        (async () => {
            try {
                let r = await API.get('/api/user/credithistory');
                if (!r)
                    r = [];
                setCreditHistory(r);
            } catch (e) {
                console.log(e);
            }
        })();
    }, []);

    function Validate() {
        if (credits < 1000) {
            alert("Minimum payment is 10 USD / 1000 Credits", "danger");
            return false;
        }
        return true;
    }

    function CreatePayment(amount, currency = "usd", method = "stripe") {
        if (Validate()) {
            (async () => {
                try {
                    let dataToFrontend = await API.post('/api/payment/createpayment', {
                        items: [{
                            id: "credit",
                            quantity: amount,
                            price: 0.01
                        }], method: method, currency: currency, total: amount * 0.01
                    });

                    window.location.href = dataToFrontend.url;
                } catch (e) {
                    alert(e, "danger");
                    console.error(e);
                }
            })();
        }
    }

    function CreateVisaPayment() {
        CreatePayment(credits);
    }

    function CreateAlipayPayment() {
        CreatePayment(credits, "cny");
    }

    return (
        <>
            <h1>Add Credits</h1>
            <p>* 1 USD = 100 Credits *</p>
            <div>
                <p>Minimum payment is 1000 Credits / 10 USD</p>
                <MDBInput label='Number of Credits' id='credits' type='number' value={credits} onChange={(e) => {
                    if (e.target.value < 1000) {
                        setCredits(1000);
                    } else {
                        setCredits(e.target.value);
                    }
                }} />
                <br />
                <MDBBtn color='secondary' onClick={CreateVisaPayment}><img style={{ height: "30px" }} src="/visa.png" alt="Visa/MasterCard/American Express" /></MDBBtn>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <MDBBtn color='secondary' onClick={CreateAlipayPayment}><img style={{ height: "30px" }} src="/alipay.png" alt="Alipay" /></MDBBtn>
            </div>
            <hr />
            <div hidden>
                <h5>Pay by Crypto</h5>
                <p>Send ETH, USDT, or USDC to 0x79beC5eF58D72ed9c5F31632790358e5cFAd42B6</p>
                <p>Accept ETH on Etherium Mainnet, USDT/USDC on Etherium Mainnet(ERC20).<br />
                    Please allow 10 minutes for system to detect transactions.<br />
                    If you encountered any problems, pleaase contact us via the Support page.</p>
                <hr />
            </div>
            <h5>Payment History</h5>
            <MDBTable style={{ fontSize: "10pt" }}>
                <MDBTableHead>
                    <tr>
                        <th scope='col'>ID</th>
                        <th scope='col'>Method</th>
                        <th scope='col'>Items</th>
                        <th scope='col'>Time</th>
                        <th scope='col'>Status</th>
                    </tr>
                </MDBTableHead>
                <MDBTableBody>
                    {
                        payments.map(({
                            paymentid,
                            method,
                            items,
                            time,
                            status
                        }) =>
                            <tr key={paymentid}>
                                <td>{paymentid}</td>
                                <td>{method}</td>
                                <td>{items.map((item) => item.id + " x " + parseFloat(item.quantity).toFixed(2) + " total $" + (item.price * item.quantity).toFixed(2)).join("\n")}</td>
                                <td>{time}</td>
                                <td>{status}</td>
                            </tr>
                        )
                    }
                </MDBTableBody>
            </MDBTable>
            <br /><hr /><br />
            <h5>Credit History for last 3 days</h5>
            <MDBTable style={{ fontSize: "10pt" }}>
                <MDBTableHead>
                    <tr>
                        <th scope='col'>Change</th>
                        <th scope='col'>Detail</th>
                        <th scope='col'>Time</th>
                    </tr>
                </MDBTableHead>
                <MDBTableBody>
                    {
                        creditHistory.map(({
                            change,
                            data,
                            time,
                        }) =>
                            <tr>
                                <td>{parseFloat(change).toFixed(4)}</td>
                                <td>{
                                    data ? Object.keys(data).map((key) => {
                                        return key + ": " + JSON.stringify(data[key]);
                                    }).join("\n") : ""
                                }</td>
                                <td>{time}</td>
                            </tr>
                        )
                    }
                </MDBTableBody>
            </MDBTable>
        </>
    );
}