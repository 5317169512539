import React, { useEffect, useState } from 'react';

import API from '../../utils/api';
import Web3 from '../../components/web3/web3';

import {
    MDBBtn, MDBInput, MDBRow, MDBCol
} from 'mdb-react-ui-kit';

export default function App() {
    const [address, setAddress] = useState(null);
    const [email, setEmail] = useState(null);
    const [verified, setVerified] = useState(true);
    const [credit, setCredit] = useState(0);

    const [myrefererid, setMyRefererID] = useState("");
    const [refererid, setRefererID] = useState("");

    useEffect(() => {
        (async () => {
            try {
                let r = await API.get('/api/user/info');
                console.log(r);
                setAddress(r.wallet);
                setEmail(r.email);
                setVerified(r.status != "needverify");
                setCredit(r.credit);
                setMyRefererID(r.myrefererid ? r.myrefererid : "");
                setRefererID(r.refererid ? r.refererid : "");
            } catch (e) {
                console.log(e);
                window.location.replace("/");
            }
        })();
    }, []);

    function web3callback(challenge) {
        (async () => {
            try {
                let r = await API.post('/api/user/updatewallet', { challenge: challenge });
                window.location.reload();
            } catch (e) {
                alert(e);
                console.log(e);
            }
        })();
    }

    function verifyEmail() {
        (async () => {
            try {
                let r = await API.post('/api/user/verifyemail');
                alert("Verification E-mail Sent");
            } catch (e) {
                alert(e);
                console.log(e);
            }
        })();
    }

    function resetPassword() {
        (async () => {
            try {
                let r = await API.post('/api/user/resetpassword', { email });
                alert("Reset Password E-mail Sent");

                //disable button
                document.getElementById("resetpassbutton").disabled = true;
            } catch (e) {
                alert(e, "danger");
                console.log(e);
            }
        })();
    }

    return (
        <>
            <h5>E-mail: {email}</h5>
            <MDBBtn style={{ visibility: !verified ? 'visible' : 'hidden' }} onClick={verifyEmail}>Verify E-mail</MDBBtn>
            <br />
            <MDBBtn className='btn-warning' id="resetpassbutton" onClick={resetPassword}>Reset Password via E-mail</MDBBtn>
            <div hidden>
                <hr />
                <h5>Link a Web3 wallet</h5>
                <p>Current Wallet: {address == null ? "None" : address}</p>
                <div style={{ maxWidth: "600px", border: '2px solid #ccc', padding: "20px" }}>
                    <Web3 buttonTitle={address == null ? "Link Web3" : "Relink Web3"} action={{ name: "connect" }} callback={web3callback} />
                </div >
            </div>
            <hr />
            <div>
                <h5>Referer Program</h5>
                <p>Your referer ID is {myrefererid}, you can share this ID.<br />
                    You will get 10% of the credits of top up from refered people.</p>
                <MDBRow>
                    <MDBCol size='2'>
                        <p>My referer is</p>
                    </MDBCol>
                    <MDBCol size='6'>
                        <MDBInput label="Referer ID" id="refererid" type="text" value={refererid} onChange={(e) => setRefererID(e.target.value)} />
                    </MDBCol>
                    <MDBCol size='3'>

                        <MDBBtn onClick={() => {
                            (async () => {
                                try {
                                    let r = await API.post('/api/user/setreferer', { refererid });
                                    alert("Referer ID Set", "success");
                                } catch (e) {
                                    alert(e, "danger");
                                    console.log(e);
                                }
                            })();
                        }}>Set Referer ID</MDBBtn>
                    </MDBCol>
                </MDBRow>
            </div>
        </>
    )
}