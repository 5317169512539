import { useEffect, useState } from 'react';

import API from '../../utils/api';

import { useWeb3React } from '@web3-react/core';
import { injected, walletconnect, resetWalletConnector, walletlink } from './connectors';

import {
  MDBBtn, MDBCol, MDBRow
} from 'mdb-react-ui-kit';

export default function Web3Func({ callback }) {
  const web3reactContext = useWeb3React();
  const [account, setAccount] = useState(web3reactContext.account);
  const [infotext, setInfotext] = useState("");

  useEffect(() => {
    if (web3reactContext.account) {
      console.log("account changed " + web3reactContext.account);
      setAccount(web3reactContext.account);

      if (web3reactContext.account) {
        //request challenge
        API.post("/api/crypto/getchallenge", { address: web3reactContext.account }).then((response) => {
          signChallenge(response);
        }).catch((e) => {
          console.log(e);
          alert(e);
        });
      }
    }
  }, [web3reactContext]);

  const signChallenge = async (message) => {
    if (!web3reactContext.account) {
      alert("Please connect wallet first!");
      return;
    }

    setInfotext("Please sign the message to verify your wallet address.");

    const signer = web3reactContext.library.getSigner();
    const signature = await signer.signMessage(message);
    console.log("signature " + signature);
    API.post("/api/crypto/verifychallenge", { challenge: message, signature: signature }).then((response) => {
      console.log(response);
      setInfotext("Wallet address verified!");
      callback(message);
    }).catch((e) => {
      console.log(e);
      alert(e);
    });
  };

  //web3react metamask
  const connectMetamaskSimple = async () => {
    try {
      await web3reactContext.activate(injected);
    } catch (ex) {
      console.log(ex);
    }
  };

  //web3react walletconnect
  const connectWalletConnectSimple = async () => {
    try {
      resetWalletConnector(walletconnect);
      await web3reactContext.activate(walletconnect);
    } catch (ex) {
      console.log(ex);
    }
  };

  //web3react coinbase
  const connectCoinbaseSimple = async () => {
    try {
      await web3reactContext.activate(walletlink);
    } catch (ex) {
      console.log(ex);
    }
  };

  return (
    <div className='text-center'>
      <div className="flex space-x-3">

        <MDBRow>
          <MDBCol size='4'>
            <MDBBtn block onClick={connectMetamaskSimple}>Metamask</MDBBtn>
          </MDBCol>
          <MDBCol size='4'>
            <MDBBtn block color='secondary' onClick={connectWalletConnectSimple}>Wallet Connect</MDBBtn>
          </MDBCol>
          <MDBCol size='4'>
            <MDBBtn block color='secondary' onClick={connectCoinbaseSimple}>Coinbase</MDBBtn>
          </MDBCol>
        </MDBRow>
        <br></br><br></br>
        <p>Connected Address: {account}</p>
        <p>{infotext}</p>
        <MDBBtn block color='warning' onClick={() => { web3reactContext.deactivate(); setAccount(null); }}>Disconnect</MDBBtn>
      </div>
    </div>
  );
}