import React, { useEffect, useState } from 'react';
import {
  MDBBtn,
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBCollapse,
  MDBIcon
} from 'mdb-react-ui-kit';
import Login from './login';
import API from '../utils/api';

export default function App() {
  const [showNav, setShowNav] = useState(false);
  const [showLogin, setShowLogin] = useState(true);

  const [padding, setPadding] = useState("8pt");

  useEffect(() => {
    (async () => {
      try {
        let r = await API.get('/api/user/info');
        setShowLogin(false);
      } catch (e) {
        API.SetCookie('token', "", true);
        API.SetCookie('token', "", false);
        setShowLogin(true);
      }
    })();
  }, []);

  return (
    <MDBNavbar expand='lg' light bgColor='light'>
      <MDBContainer fluid>
        <MDBNavbarBrand href='/'><img style={{ height: "18pt" }} src="/logo.png" /></MDBNavbarBrand>
        <MDBNavbarToggler
          type='button'
          aria-expanded='false'
          aria-label='Toggle navigation'
          onClick={() => setShowNav(!showNav)}
        >
          <MDBIcon icon='bars' fas />
        </MDBNavbarToggler>
        <MDBCollapse navbar show={showNav}>
          <MDBNavbarNav>
            <MDBNavbarItem>
              <MDBNavbarLink active href='/' style={{ paddingInline: padding }}>
                Home
              </MDBNavbarLink>
            </MDBNavbarItem>

            <MDBNavbarItem>
              <MDBNavbarLink href='/freeimage' style={{ paddingInline: padding }}>Free Image</MDBNavbarLink>
            </MDBNavbarItem>

            <MDBNavbarItem>
              <MDBNavbarLink href='/freetts' style={{ paddingInline: padding }}>Free TTS</MDBNavbarLink>
            </MDBNavbarItem>

            <MDBNavbarItem hidden>
              <MDBNavbarLink href='/games' style={{ paddingInline: padding }}>Games</MDBNavbarLink>
            </MDBNavbarItem>

            <MDBNavbarItem>
              <MDBNavbarLink href='/services' style={{ paddingInline: padding }}>Easy AI</MDBNavbarLink>
            </MDBNavbarItem>

            <MDBNavbarItem>
              <MDBNavbarLink href='/support' style={{ paddingInline: padding }}>Support</MDBNavbarLink>
            </MDBNavbarItem>

          </MDBNavbarNav>
          {showLogin ? <Login buttonTitle='Login' jumpto='/user' /> : <MDBBtn color='light' href='/user'>Board</MDBBtn>}
        </MDBCollapse>
      </MDBContainer>
    </MDBNavbar>
  );
}