import React, { useEffect, useState } from 'react';
import API from '../utils/api';
import { MDBBtn, MDBSelect, MDBTextArea, MDBRange, MDBInput, MDBRow, MDBCol } from 'mdb-react-ui-kit';

export default function App() {
    const [text, setText] = useState("");
    const [voice, setVoice] = useState("");
    const [charcount, setCharCount] = useState(0);

    const [tabledata, setTableData] = useState([]);
    const [used, setUsed] = useState(0);

    function UpdateQuota() {
        (async () => {
            try {
                let r = await API.get('/api/workerjob/ipfreequota');
                setUsed(r.count);
            } catch (e) {
                setUsed(0);
                console.log(e);
            }
        })();
    }

    useEffect(() => {
        setCharCount(text.length);
    }, [text]);

    useEffect(() => {
        //set table data row defaultSelected
        setTableData(tabledata.map(x => {
            if (x.value == voice) {
                x.defaultSelected = true;
            } else {
                x.defaultSelected = false;
            }
            return x;
        }));
    }, [voice]);

    useEffect(() => {
        (async () => {
            try {
                let r = await API.get('/api/workerjob/table/tts');
                //r is [text]
                r = r.map(x => { return { text: x, value: x } });
                //set first defaultSelected
                r[0].defaultSelected = true;
                setVoice(r[0].value);
                setTableData(r)
            } catch (e) {
                alert(e, "danger");
                console.log(e);
            }

            UpdateQuota();
        })();
    }, []);

    function CreateTask() {
        (async () => {
            try {
                let r = await API.post('/api/workerjob/freetts', { text: text, voicename: voice });
                let buf = r.audio.data;
                let blob = new Blob([new Uint8Array(buf)], { type: 'audio/mpeg' });
                //download
                let a = document.createElement('a');
                a.href = window.URL.createObjectURL(blob);
                a.download = 'tts.mp3';
                a.click();
                setTimeout(() => {
                    //remove element
                    window.URL.revokeObjectURL(a.href);
                    a.remove();
                }, 500);
            } catch (e) {
                alert(e, "danger");
                console.log(e);
            }

            UpdateQuota();
        })();
    }
    return (
        <div style={{ maxWidth: "600pt", margin: "auto", padding: "20pt" }}>
            <br />
            <h3>FREE Text to Speech</h3>
            <div>
                <div>
                    <br />
                    <MDBTextArea id={"text"} value={text} rows={3} label="Text" onChange={
                        (e) => {
                            setText(e.target.value);
                        }
                    } />
                    <br />
                    <MDBSelect
                        id={"voice"}
                        label='Voice'
                        data={tabledata}
                        visibleOptions={10}
                        onValueChange={(e) => {
                            setVoice(e.value);
                        }}
                    />
                    <a href="/ttssample" target="_blank">See Samples</a>
                    <br />
                    <p>Character Count: {charcount}</p>
                    <p>Free Limit: 1000 characters per IP address per week<br />
                        Used: {used}</p>
                    <MDBBtn id="createbtn" onClick={CreateTask}>Generate</MDBBtn>
                </div>
            </div>
        </div>
    );
}