import React, { useEffect } from 'react';
import env from '../utils/env';
import {
  MDBCarousel,
  MDBCarouselItem,
  MDBBtn,
} from 'mdb-react-ui-kit';

import Gallery from '../components/gallery';

export default function App() {
  useEffect(() => {
    console.log(env);
  }, []);

  return (
    <>
      <div style={{
        width: "100%",
        backgroundColor: "#555",
        backgroundImage: `url("/background_services.png")`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundBlendMode: "multiply",
        paddingTop: "60pt",
        paddingBottom: "60pt"
      }}>
        <div style={{
          width: "100%",
          textAlign: "center",
          color: "white"
        }}>
          <h1 style={{ fontSize: "50pt" }}>One Stop AI Toolset</h1>
          <h3>GPT, Image Creation, Text-to-speech...</h3>
          <br />
          <br />
          <MDBBtn id={"sendbtn"} outline color='light' size="lg" style={{ textTransform: "none" }} onClick={window.showlogin}>Sign up for free</MDBBtn>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
          <MDBBtn id={"sendbtn"} outline color='light' size="lg" style={{ textTransform: "none" }} onClick={
            () => {
              window.location.href = "/freeimage"
            }
          }>Try image generation</MDBBtn>
        </div>
      </div>
      <Gallery />
    </>
  );
}