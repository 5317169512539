import React, { useState } from 'react';
import { MDBAlert, MDBBtn, MDBContainer } from 'mdb-react-ui-kit';

var stackID = 0;

export default function App() {
    const [stackingAlerts, setStackingAlerts] = useState([]);

    //const colors = ['primary', 'warning', 'info', 'success', 'secondary', 'danger', 'light'];

    window.alert = (text, type = "info") => {
        if (typeof text !== 'string') {
            text = JSON.stringify(text);
        }

        if (type == "error") {
            type = "danger";
        }

        setStackingAlerts([
            ...stackingAlerts,
            {
                color: type,
                id: stackID++,
                text: text
            },
        ]);
    }

    return (
        <MDBContainer>
            {
                stackingAlerts.map((item) => {
                    return (
                        <MDBAlert
                            className='d-flex justify-content-between'
                            color={item.color}
                            stacking
                            autohide
                            position='top-right'
                            delay={3000}
                            appendToBody
                            show={true}
                            key={item.id}
                        >
                            <p className='mb-0'>
                                {item.text}
                            </p>
                        </MDBAlert>
                    );
                })
            }
        </MDBContainer>
    );
}