import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { WalletLinkConnector } from '@web3-react/walletlink-connector';
import env from '../../utils/env'

const RPC_URLS = {
	1: 'https://mainnet.infura.io/v3/b72228da77ae4122a3979c6b0cd0119c',
	56: 'https://misty-alpha-fog.bsc.discover.quiknode.pro/c30fce578bc9089a2a5960d902d0ddef90c26c0d/', //bsc
	137: 'https://polygon-mainnet.infura.io/v3/b72228da77ae4122a3979c6b0cd0119c' //matic
};

//metamask
export const injected = new InjectedConnector({
	supportedChainIds: [ 1, 56, 137 ]
});


export const walletconnect = new WalletConnectConnector({
	rpc: {
		1: RPC_URLS[1],
		56: RPC_URLS[56],
		137: RPC_URLS[137]
	},
	qrcode: true,
	pollingInterval: 15000
});


export function resetWalletConnector(connector) {
	if (connector && connector instanceof WalletConnectConnector) {
		connector.walletConnectProvider = undefined;
	}
}

//coinbase
export const walletlink = new WalletLinkConnector({
	url: RPC_URLS[1],
	appName: env.domainname,
	supportedChainIds: [ 1, 56, 137 ]
});