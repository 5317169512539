import React from 'react';

export default function App() {
  return (
    //center of window
    <div style={{
        position: 'absolute', left: '50%', top: '50%',
        transform: 'translate(-50%, -50%)'
    }}>
        <div className='text-center'>
            <h1 className='display-1'>404</h1>
            <h1 className='display-4'>Page not found</h1>
            <p className='lead'>The page you are looking for does not exist.</p>
        </div>
    </div>
  );
}