import React, { useEffect, useState } from 'react';
import API from '../../utils/api';
import { MDBBtn, MDBTextArea } from 'mdb-react-ui-kit';

export default function App() {
    const [messages, setMessages] = useState([{
        id: "system",
        role: "system",
        content: "You are an assistant to ..."
    }, {
        id: "user1",
        role: "user",
        content: "What is ..."
    }]);

    function RandomString(len = 16) {
        var text = "";
        var charset = "abcdefghijklmnopqrstuvwxyz0123456789";
        for (var i = 0; i < len; i++)
            text += charset.charAt(Math.floor(Math.random() * charset.length));
        return text;
    }

    function SendMessages() {
        //set text to thinking
        //document.getElementById("sendbtn").innerHTML = "Thinking...";
        (async () => {
            alert("Thinking...", "info")
            try {
                //remove all ids
                let r = await API.post('/api/workerjob/gpt', { messages: messages.map(x => { return { role: x.role, content: x.content } }) });
                console.log(r);
                let msg = r.message;
                msg.id = RandomString(10);

                //add to messages
                setMessages([...messages, msg, { id: RandomString(10), role: "user", content: "" }]);

                //document.getElementById("sendbtn").innerHTML = "Send Message";

                //scroll to bottom
                setTimeout(() => {
                    window.scrollTo(0, document.body.scrollHeight);
                }, 100);
            } catch (e) {
                //document.getElementById("sendbtn").innerHTML = "Send Message";

                alert(e, "danger");
                console.log(e);
            }

            window.UpdateCredits();
        })();
    }

    //message: {role, content}
    //loop through messages and display them
    return (
        <>
            <h3>GPT</h3>
            <p>
                You can specify instructions in the System section.<br />
                Price: ~3 credits per 1000 words or non-English characters.<br />
                The words and characters are counted by adding all sections and the response together everytime you send a message<br />
                There is an overall limit of around 2000-3000 words or non-English characters.<br />
                Failed requests will not be charged.<br />
            </p>
            <p style={{ color: "red" }}>Disclaimer: This service uses third-party APIs and yozorasoft does not endorse any of them. This service does not represent our political and religional views, and may provide inaccurate information. Please use according to your local laws and regulations.</p>
            <div>
                {
                    messages.map(({ id, role, content }) =>
                        //title: role
                        //content: content
                        <div key={id}>
                            <p>&nbsp;&nbsp;&nbsp;&nbsp;- {role.toUpperCase()} -&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <MDBBtn color="danger" onClick={() => {
                                    setMessages(messages.filter(x => x.id != id));
                                }}>Delete</MDBBtn>
                            </p>
                            <MDBTextArea value={content} rows={5} onChange={(e) => {
                                //update messages
                                setMessages(messages.map(x => {
                                    if (x.id == id) {
                                        x.content = e.target.value;
                                    }
                                    return x;
                                }));
                            }} />
                            <br />
                        </div>
                    )
                }
            </div>

            <MDBBtn id={"sendbtn"} color='primary' className='m-1' onClick={SendMessages}>Send Message</MDBBtn>
            <br /><br />
        </>
    );
}