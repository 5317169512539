import React, { useEffect, useState } from 'react';
import API from '../../utils/api';
import { MDBBtn, MDBSelect, MDBTextArea, MDBRange, MDBInput, MDBRow, MDBCol, MDBCheckbox } from 'mdb-react-ui-kit';
import { MDBFileUpload } from 'mdb-react-file-upload';
import WorkerJobTable from '../../components/workerjobtable';
import { AudioRecorder } from 'react-audio-voice-recorder';

export default function App() {
    const costPerImage = 2.0;

    const type = "waifu2x";

    const [myJobs, setMyJobs] = useState([]);
    const [queueNum, setQueueNum] = useState(0);

    const [file, setFile] = useState(null);

    useEffect(() => {
        RefreshTasks();
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            RefreshTasks();
        }, 5000);
        return () => clearInterval(interval);
    }, []);

    function RefreshTasks() {
        (async () => {
            try {
                let r = await API.get('/api/workerjob/myjobs/' + type);
                console.log(r);
                setMyJobs(r.jobs);
                setQueueNum(r.pending);
            } catch (e) {
                alert(e, "danger");
                console.log(e);
            }
        })();
    }

    function CreateTask() {
        //upload file
        if (file == null) {
            alert("Please upload a file", "danger");
            return;
        }

        //check file size
        if (file.size > 5 * 1024 * 1024) {
            alert("Max file size: 5MB", "danger");
            return;
        }

        //disable button
        document.getElementById("createbtn").disabled = true;
        (async () => {
            let data = {
                type: type,
                input: {},
                files: []
            };

            try {
                let fileresult = await API.upload('/api/workerjob/uploadfile', file);
                console.log(fileresult);
                data.files.push(fileresult.file);
            } catch (e) {
                alert(e, "danger");
                console.error(e);
                document.getElementById("createbtn").disabled = false;
                return;
            }

            try {
                let r = await API.post('/api/workerjob/addjob', data);
                console.log(r);
                alert("Task created");
            } catch (e) {
                alert(e, "danger");
                console.log(e);
            }

            document.getElementById("createbtn").disabled = false;
            RefreshTasks();
            window.UpdateCredits();
        })();
    }

    //message: {role, content}
    //loop through messages and display them
    return (
        <>
            <h3>Denoise & Enlarge</h3>
            <div>
                <p>Denoise and enlarge an image 2x</p>
                <p>You can view task results later below<br />
                    Currently waiting tasks (all users): {queueNum}</p>
                <p>Max file size: 5MB</p>
                <div>
                    <MDBFileUpload id='fileupload' label='Upload' getInputFiles={(files) => {
                        console.log("Selected files:", files);
                        if (files.length > 0) {
                            //check file size
                            if (files[0].size > 5 * 1024 * 1024) {
                                alert("Max file size: 5MB", "danger");
                            }

                            setFile(files[0]);
                        }
                    }} />
                    <br />
                    <p>Cost: 1 credit</p>
                    <p>Failed tasks will be refunded</p>
                    <MDBBtn id="createbtn" onClick={CreateTask}>Create Task</MDBBtn>
                </div>
            </div>
            <hr />
            <h5>My Tasks</h5>
            <p>
                Tasks over 3 days will be deleted<br />
            </p>
            <MDBBtn outline color='dark' onClick={RefreshTasks}>Refresh</MDBBtn>
            <WorkerJobTable jobs={myJobs} />
        </>
    );
}