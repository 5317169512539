import React, { useEffect, useState } from 'react';
import API from '../../utils/api';
import { MDBBtn, MDBSelect, MDBTextArea, MDBRange, MDBInput, MDBRow, MDBCol, MDBCheckbox } from 'mdb-react-ui-kit';
import { MDBFileUpload } from 'mdb-react-file-upload';
import WorkerJobTable from '../../components/workerjobtable';

export default function App() {
    const [notAvailable, setNotAvailable] = useState(false);

    const maxsize = 1280;
    const maxcostbase = maxsize * maxsize * 100;
    const maxcost = 30;
    const mincost = 1.8;

    const type = "imagecreation";

    const [myJobs, setMyJobs] = useState([]);
    const [queueNum, setQueueNum] = useState(0);
    const [cost, setCost] = useState(3);

    const [prompt, setPrompt] = useState("");
    const [negative, setNegative] = useState("(normal quality), (low quality), (worst quality), disfigured, ugly, oversaturated, grain, low-res, deformed, blurry, bad anatomy, disfigured, poorly drawn face, mutation, mutated, extra limb, poorly drawn hands, missing limb, blurry, floating limbs, disconnected limbs, malformed hands, blur, long neck, long body, disgusting, poorly drawn, mangled, old");

    const [scale, setScale] = useState(7);
    const [steps, setSteps] = useState(20);
    const [width, setWidth] = useState(500);
    const [height, setHeight] = useState(750);
    const [seed, setSeed] = useState(-1);
    const [model, setModel] = useState("");
    const [file, setFile] = useState(null);
    const [worktype, setWorktype] = useState("txt2img");
    const [explicitlora, setExplicitLora] = useState(true);

    const [tabledata, setTableData] = useState([]);

    useEffect(() => {
        updateCost();
    }, [model, worktype]);

    function updateCost() {
        setPrompt(document.getElementById("prompt").value);
        setNegative(document.getElementById("negative").value);
        setScale(document.getElementById("scale").value);
        setSteps(document.getElementById("steps").value);
        setWidth(document.getElementById("width").value);
        setHeight(document.getElementById("height").value);
        //setSeed(document.getElementById("seed").value);
        let worktypecost = worktype == "txt2img" ? 0 : 2;
        let calccost = parseInt((maxcost - mincost) * steps * width * height / maxcostbase + mincost + worktypecost);
        setCost(calccost);
    }

    useEffect(() => {
        //set table data row defaultSelected
        setTableData(tabledata.map(x => {
            if (x.value == model) {
                x.defaultSelected = true;
            } else {
                x.defaultSelected = false;
            }
            return x;
        }));
    }, [model]);

    useEffect(() => {
        (async () => {
            try {
                let r = await API.get('/api/workerjob/table/' + type);
                //r is [text]
                r = r.map(x => { return { text: x, value: x } });
                //set first defaultSelected
                r[0].defaultSelected = true;
                setModel(r[0].value);
                setTableData(r)
            } catch (e) {
                alert(e, "danger");
                console.log(e);
            }
        })();

        (async () => {
            try {
                let r = await API.get('/api/user/country');
                let available = r.available;
                if (!available) {
                    setNotAvailable(true);
                }
            } catch (e) {
                setNotAvailable(true);
            }
        })();

        updateCost();
        RefreshTasks();
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            RefreshTasks();
        }, 5000);
        return () => clearInterval(interval);
    }, []);

    function RefreshTasks() {
        (async () => {
            try {
                let r = await API.get('/api/workerjob/myjobs/' + type);
                console.log(r);
                //remove key negative from userinput
                r.jobs = r.jobs.map(x => {
                    if (x.userinput) {
                        delete x.userinput.negative;
                    }
                    return x;
                });
                setMyJobs(r.jobs);
                setQueueNum(r.pending);
            } catch (e) {
                alert(e, "danger");
                console.log(e);
            }
        })();
    }

    function ProcessPrompt(p) {
        const nsfw = true;
        if (p == null) return "";
        if (!nsfw) {
            //remove nsfw words
            p = p.replace(/nsfw/gi, "");
            p = p.replace(/nude/gi, "");
            p = p.replace(/naked/gi, "");
            p = p.replace(/nudity/gi, "");
            p = p.replace(/nakedness/gi, "");
            p = p.replace(/nude/gi, "");
            p = p.replace(/nake/gi, "");
            p = p.replace(/hentai/gi, "");
            p = p.replace(/masturbation/gi, "");
            p = p.replace(/sex/gi, "");
            p = p.replace(/fuck/gi, "");
            p = p.replace(/pee/gi, "");
        }

        if (model == "Seamless Texture") {
            if (!p.includes("pbr"))
                p = "pbr, " + p;
        }

        return p;
    }

    function CreateTask() {
        if (worktype != "txt2img") {
            if (file == null) {
                alert("Please upload a reference image ", "danger");
                return;
            }
        }

        //disable button
        document.getElementById("createbtn").disabled = true;

        (async () => {
            let data = {
                type: type,
                input: {
                    prompt: ProcessPrompt(prompt),
                    negative: negative,
                    model: model,
                    scale: scale,
                    steps: steps,
                    width: width,
                    height: height,
                    seed: seed,
                    worktype: worktype,
                    useExplicitLora: explicitlora
                },
                files: []
            };

            if (file != null && worktype != "txt2img") {
                try {
                    let fileresult = await API.upload('/api/workerjob/uploadfile', file);
                    console.log(fileresult);
                    data.files.push(fileresult.file);
                } catch (e) {
                    alert(e, "danger");
                    console.error(e);
                    document.getElementById("createbtn").disabled = false;
                    return;
                }
            }

            try {
                let r = await API.post('/api/workerjob/addjob', data);
                console.log(r);
                alert("Task created");
            } catch (e) {
                alert(e, "danger");
                console.log(e);
            }

            document.getElementById("createbtn").disabled = false;
            RefreshTasks();
            window.UpdateCredits();
        })();
    }

    //message: {role, content}
    //loop through messages and display them
    return (
        <>
            <h3>Image Creation</h3>
            {notAvailable && <p style={{ color: "red" }}>This service is not available at your country.</p>}
            <div hidden={notAvailable}>
                <p>You can view task results later below<br />
                    Currently waiting tasks (all users): {queueNum}</p>
                <p style={{ color: "red" }}>Disclaimer: This service uses third-party APIs and yozorasoft does not endorse any of them. This service does not represent our political and religional views, and may provide sensitive or NSFW content. You must be at least 18 years old when prompting NSFW instructions. Please use according to your local laws and regulations.</p>
                <div>
                    <p>Prompt</p>
                    <MDBTextArea id={"prompt"} value={prompt} rows={3} onChange={updateCost} />
                    <br />
                    <p>Negative</p>
                    <MDBTextArea id={"negative"} value={negative} rows={3} onChange={updateCost} />
                    <br />
                    <MDBRow>
                        <MDBCol size='12' sm='12'>
                            <MDBCheckbox id='explicitlora' label='Use Explicit LoRA <lora:LoRA name:weight>, will auto detect trigger words if unchecked. Leave this checked to prevent word conflict or override to original prompt.' checked={explicitlora} onChange={
                                (e) => {
                                    setExplicitLora(e.target.checked);
                                }
                            } />
                        </MDBCol>
                        <MDBCol size='12' sm='12'>
                            <MDBBtn id={"sendbtn"} outline color='dark' style={{ textTransform: "none" }} onClick={
                                () => {
                                    window.open("/user/loraupload", "_blank");
                                }
                            }>Upload My LoRA</MDBBtn>
                        </MDBCol>
                    </MDBRow>
                    <br />
                    <MDBRow>
                        <MDBCol size='6'>
                            <MDBSelect
                                id={"worktype"}
                                label='Work Type'
                                data={[
                                    { text: 'Text to Image', value: 'txt2img', defaultSelected: worktype === "txt2img" },
                                    { text: 'Image to Image', value: 'img2img', defaultSelected: worktype === "img2img" },
                                    { text: 'Reference Pose', value: 'pose', defaultSelected: worktype === "pose" },
                                    { text: 'Reference Outline', value: 'lineart', defaultSelected: worktype === "lineart" },
                                ]}
                                visibleOptions={10}
                                onValueChange={(e) => {
                                    setWorktype(e.value);
                                }}
                            />
                        </MDBCol>
                        <MDBCol size='6'>
                            <div hidden={worktype == "txt2img"}>
                                <p>Reference File (jpg or png, max {maxsize}x{maxsize} pixels)</p>
                                <MDBFileUpload id='fileupload' label='Upload' getInputFiles={(files) => {
                                    console.log("Selected files:", files);
                                    setFile(files[0]);
                                }} />
                            </div>
                        </MDBCol>
                    </MDBRow>
                    <br />
                    <MDBRow>
                        <MDBCol size='6'>
                            <MDBRange
                                defaultValue={scale}
                                min='1'
                                max='30'
                                step='1'
                                id='scale'
                                label={'CFG Scale (1-30): ' + scale}
                                onChange={updateCost}
                            />
                        </MDBCol>
                        <MDBCol size='6'>
                            <MDBRange
                                defaultValue={steps}
                                min='1'
                                max='100'
                                step='1'
                                id='steps'
                                label={'Steps (1-100): ' + steps}
                                onChange={updateCost}
                            />
                        </MDBCol>
                    </MDBRow>

                    <MDBRow>
                        <MDBCol size='6'>
                            <MDBRange
                                defaultValue={width}
                                min='256'
                                max={maxsize}
                                step='1'
                                id='width'
                                label={'Width (max ' + maxsize + '): ' + width}
                                onChange={updateCost}
                            />
                        </MDBCol>
                        <MDBCol size='6'>
                            <MDBRange
                                defaultValue={height}
                                min='256'
                                max={maxsize}
                                step='1'
                                id='height'
                                label={'Height (max ' + maxsize + '): ' + height}
                                onChange={updateCost}
                            />
                        </MDBCol>
                    </MDBRow>
                    <br />
                    <MDBRow>
                        <MDBCol size='4'>
                            <MDBSelect
                                id={"model"}
                                label='Model'
                                data={tabledata}
                                visibleOptions={10}
                                onValueChange={(e) => {
                                    setModel(e.value);
                                }}
                            />
                            <a href="/imagecreationsample" target="_blank">See Samples</a>
                        </MDBCol>
                    </MDBRow>
                    <p>Cost: {cost} credits<br />Failed tasks will be refunded</p>
                    <MDBBtn id="createbtn" onClick={CreateTask}>Create Task</MDBBtn>
                </div>
            </div>
            <hr />
            <h5>My Tasks</h5>
            <p>
                Tasks over 3 days will be deleted<br />
            </p>
            <MDBBtn outline color='dark' onClick={RefreshTasks}>Refresh</MDBBtn>
            <WorkerJobTable jobs={myJobs} />
        </>
    );
}