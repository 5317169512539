import React from 'react';

export default function App() {
  return (
    <div style={{ "padding": "30px" }}>
      <strong>Terms of Service</strong>
      <br />
      <br />
      <p>
        Welcome to our website! We offer paid AI services and games for your enjoyment. By accessing or using our website, you agree to be bound by the following terms and conditions:
        <br />
        <br />

        1. Acceptance of Terms: By using our website, you agree to these Terms of Service and any additional terms and conditions that may apply to specific sections of our website or to products and services that we offer. If you do not agree to these terms, please do not use our website.
        <br />
        <br />

        2. Use of Services: Our website offers a variety of AI services and games that are available for purchase. By purchasing our services, you agree to abide by these terms and any additional terms and conditions that may apply. You may not use our services for any illegal or unauthorized purpose.
        <br />
        <br />

        3. Payment: All payments for our services are processed through a third-party payment processor. By using our services, you agree to pay all fees and charges associated with your use of our services. Any fees and charges are non-refundable.
        <br />
        <br />

        4. Intellectual Property: All content on our website, including but not limited to text, graphics, logos, images, and software, is the property of our company or its licensors and is protected by copyright, trademark, and other intellectual property laws. You may not use any content on our website without our prior written consent.
        <br />
        <br />

        5. Disclaimer of Warranties: Our website and all content, products, and services available through our website are provided “as is” and “as available” without any warranty of any kind, either express or implied. We do not warrant that our website or our services will be uninterrupted or error-free.
        <br />
        <br />

        6. Limitation of Liability: In no event shall our company or its affiliates, officers, directors, employees, agents, or licensors be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with your use of our website or our services.
        <br />
        <br />

        7. Indemnification: You agree to indemnify and hold our company and its affiliates, officers, directors, employees, agents, and licensors harmless from any claim or demand, including reasonable attorneys’ fees, made by any third-party due to or arising out of your use of our website or our services.
        <br />
        <br />

        8. Termination: We reserve the right to terminate your access to our website or our services at any time, without notice, for any reason.
        <br />
        <br />

        9. Governing Law: These Terms of Service shall be governed by and construed in accordance with the laws of the state in which our company is incorporated.
        <br />
        <br />

        10. Changes to Terms of Service: We reserve the right to modify these Terms of Service at any time. Your continued use of our website or our services after any such modifications shall constitute your acceptance of such modifications.
        <br />
        <br />

        11. Out of Service: Some services may be out of service due to maintenance or other reasons. We are not responsible for any losses or damages that may occur as a result of such outages.
        <br />
        <br />

        Thank you for using our website and our services. If you have any questions or comments about these Terms of Service, please contact us.
      </p>
    </div>
  );
}