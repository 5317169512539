import React, { useEffect, useState } from 'react';
import API from '../../utils/api';
import { MDBBtn, MDBSelect, MDBTextArea, MDBRange, MDBInput, MDBRow, MDBCol, MDBCheckbox } from 'mdb-react-ui-kit';
import { MDBFileUpload } from 'mdb-react-file-upload';
import WorkerJobTable from '../../components/workerjobtable';
import { AudioRecorder } from 'react-audio-voice-recorder';

export default function App() {
    const costPerMin = 2.0;

    const type = "voiceconvert";

    const [myJobs, setMyJobs] = useState([]);
    const [queueNum, setQueueNum] = useState(0);

    const [cost, setCost] = useState(0);

    const [sing, setSing] = useState(false);
    const [model, setModel] = useState("");
    const [file, setFile] = useState(null);

    const [tabledata, setTableData] = useState([]);

    useEffect(() => {
        let audio = document.getElementById("audio");
        audio.addEventListener("loadedmetadata", function () {
            audio.removeEventListener("loadedmetadata", function () { });

            let calccost = parseInt(audio.duration / 60.0 * costPerMin) + 1;
            setCost(calccost);
        });


        (async () => {
            try {
                let r = await API.get('/api/workerjob/table/' + type);
                //r is [text]
                r = r.map(x => { return { text: x, value: x } });
                //set first defaultSelected
                r[0].defaultSelected = true;
                setModel(r[0].value);
                setTableData(r)
            } catch (e) {
                alert(e, "danger");
                console.log(e);
            }
        })();

        RefreshTasks();
    }, []);

    function onRecorded(blob) {
        let _file = new File([blob], "recorded.mp3", { type: "audio/mpeg" });
        setFile(_file);
    }
    
    useEffect(() => {
        const interval = setInterval(() => {
            RefreshTasks();
        }, 5000);
        return () => clearInterval(interval);
    }, []);

    function RefreshTasks() {
        (async () => {
            try {
                let r = await API.get('/api/workerjob/myjobs/' + type);
                console.log(r);
                setMyJobs(r.jobs);
                setQueueNum(r.pending);
            } catch (e) {
                alert(e, "danger");
                console.log(e);
            }
        })();
    }

    useEffect(() => {
        //set table data row defaultSelected
        setTableData(tabledata.map(x => {
            if (x.value == model) {
                x.defaultSelected = true;
            } else {
                x.defaultSelected = false;
            }
            return x;
        }));
    }, [model]);

    useEffect(() => {
        let audio = document.getElementById("audio");
        if (file != null) {
            audio.src = URL.createObjectURL(file);
            audio.load();
        } else {
            setCost(0);
        }
    }, [file]);

    function CreateTask() {
        //upload file
        if (file == null) {
            alert("Please upload a file", "danger");
            return;
        }

        //check if file a valid audio
        if (file.type != "audio/mp3" && file.type != "audio/wav" && file.type != "audio/mpeg" && file.type != "audio/ogg") {
            alert("Please upload a valid audio file", "danger");
            return;
        }

        //disable button
        document.getElementById("createbtn").disabled = true;
        (async () => {
            let data = {
                type: type,
                input: {
                    model: model,
                    sing: sing
                },
                files: []
            };

            try {
                let fileresult = await API.upload('/api/workerjob/uploadfile', file);
                console.log(fileresult);
                data.files.push(fileresult.file);
            } catch (e) {
                alert(e, "danger");
                console.error(e);
                document.getElementById("createbtn").disabled = false;
                return;
            }

            try {
                let r = await API.post('/api/workerjob/addjob', data);
                console.log(r);
                alert("Task created");
            } catch (e) {
                alert(e, "danger");
                console.log(e);
            }

            document.getElementById("createbtn").disabled = false;
            RefreshTasks();
            window.UpdateCredits();
        })();
    }

    //message: {role, content}
    //loop through messages and display them
    return (
        <>
            <h3>Voice Convert</h3>
            <div>
                <p>Covert a voice or a song. You may use results from TTS as input.</p>
                <p>You can view task results later below<br />
                    If you wish to convert a song, you need to remove background music in the original file (recommended tool: UVR5) before uploading, and select Sing below.<br />
                    The models can be used with any language, but they work better with their original languages.<br />
                    Currently waiting tasks (all users): {queueNum}</p>
                <div>

                    <MDBRow>
                        <MDBCol size='6'>
                            <p>Your Voice (mp3 or wav, max 20MB)</p>
                            <MDBFileUpload id='fileupload' label='Upload' getInputFiles={(files) => {
                                console.log("Selected files:", files);
                                if (files.length > 0)
                                    setFile(files[0]);
                            }} />
                        </MDBCol>
                        <MDBCol size='6' hidden>
                            <p>or record</p>
                            <AudioRecorder onRecordingComplete={onRecorded} />
                        </MDBCol>
                    </MDBRow>
                    <audio id="audio" controls hidden={file == null}></audio>
                    <br />
                    <MDBCheckbox id='sing' label='Is this a song?' checked={sing} onChange={(e) => {
                        setSing(e.target.checked);
                    }} />
                    <br />
                    <MDBSelect
                        id={"model"}
                        label='Model'
                        data={tabledata}
                        visibleOptions={10}
                        onValueChange={(e) => {
                            setModel(e.value);
                        }}
                    />
                    <a href="/voiceconvertsample" target="_blank">See Samples</a>
                    <br />
                    <p>Cost: {cost} credits<br />Failed tasks will be refunded</p>
                    <MDBBtn id="createbtn" onClick={CreateTask}>Create Task</MDBBtn>
                </div>
            </div>
            <hr />
            <h5>My Tasks</h5>
            <p>
                Tasks over 3 days will be deleted<br />
            </p>
            <MDBBtn outline color='dark' onClick={RefreshTasks}>Refresh</MDBBtn>
            <WorkerJobTable jobs={myJobs} />
        </>
    );
}