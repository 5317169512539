//react post
import env from "./env";
import axios from "axios";

var api = {};

api.GetURLParams = () => {
    let url = window.location.href;
    let queryStart = url.indexOf("?") + 1,
        queryEnd = url.indexOf("#") + 1 || url.length + 1,
        query = url.slice(queryStart, queryEnd - 1),
        pairs = query.replace(/\+/g, " ").split("&"),
        parms = {},
        i, n, v, nv;

    if (query === url || query === "") return;

    for (i = 0; i < pairs.length; i++) {
        nv = pairs[i].split("=", 2);
        n = decodeURIComponent(nv[0]);
        v = decodeURIComponent(nv[1]);

        parms[n] = v;
    }
    return parms;
}

api.SetCookie = (key, value, persistent = true) => {
    if (persistent)
        localStorage.setItem(key, value);
    else
        sessionStorage.setItem(key, value);
}

api.GetCookie = (key) => {
    return localStorage.getItem(key) || sessionStorage.getItem(key);
}

api.startsWith = (str, prefix) => {
    return str.indexOf(prefix) === 0;
}

api.GetURLofIPFS = (ipfslink) => {
    if (!ipfslink)
        return "";

    if (ipfslink.startsWith("ipfs://")) {
        ipfslink = ipfslink.replace("ipfs://", "https://ipfs.io/ipfs/");
    }
    return ipfslink;
}

api.upload = function (path, file) {
    return new Promise((resolve, reject) => {
        const server = env.server;

        let internalApi = true;
        if (path.startsWith("http")) {
            internalApi = false;
        } else {
            if (!path.startsWith("/"))
                path = "/" + path;
            path = server + path;
        }

        let auth = "";
        if (internalApi) {
            auth = api.GetCookie("token");
        }

        const config = {
            headers: {
                auth: auth
            }
        };

        const formData = new FormData();
        formData.append("file", file);

        axios.post(path, formData, config)
            .then((response) => {
                let data = response.data;
                console.log(data);

                if (data.error) {
                    reject(data.error);
                    return;
                } else if (!data.success) {
                    if (data.reason)
                        reject(data.reason);
                    else
                        reject(data);
                    return;
                }

                resolve(data.data);
            }).catch((error) => {
                console.log(error);
                //get data
                if (error.response) {
                    let data = error.response.data;
                    if (data.error) {
                        reject(data.error || data.reason);
                        return;
                    }
                }
                reject(error.message);
            });;
    });
}

api.get = function (path) {
    console.log("api.get", path);

    return new Promise((resolve, reject) => {
        const server = env.server;

        let internalApi = true;
        if (path.startsWith("http")) {
            internalApi = false;
        } else {
            if (!path.startsWith("/"))
                path = "/" + path;
            path = server + path;
        }

        let auth = "";
        if (internalApi) {
            auth = api.GetCookie("token");
        }

        const config = {
            headers: {
                auth: auth
            }
        };

        axios.get(path, config)
            .then((response) => {
                let data = response.data;
                console.log(data);

                if (data.error) {
                    reject(data.error);
                    return;
                } else if (!data.success) {
                    if (data.reason)
                        reject(data.reason);
                    else
                        reject(data);
                    return;
                }

                resolve(data.data);
            })
            .catch((error) => {
                console.log(error);
                //get data
                if (error.response) {
                    let data = error.response.data;
                    if (data.error) {
                        reject(data.error || data.reason);
                        return;
                    }
                }
                reject(error.message);
            });
    });
}

api.post = function (path, data) {
    console.log("api.post", path, data);

    return new Promise((resolve, reject) => {
        const server = env.server;

        let internalApi = true;
        if (path.startsWith("http")) {
            internalApi = false;
        } else {
            if (!path.startsWith("/"))
                path = "/" + path;
            path = server + path;
        }

        let auth = "";
        if (internalApi) {
            auth = api.GetCookie("token");
        }

        const config = {
            headers: {
                auth: auth,
                'Content-Type': 'application/json'
            }
        };

        axios.post(path, JSON.stringify(data), config)
            .then((response) => {
                let data = response.data;
                console.log(data);

                if (data.error) {
                    reject(data.error);
                    return;
                } else if (!data.success) {
                    if (data.reason)
                        reject(data.reason);
                    else
                        reject(data);
                    return;
                }

                resolve(data.data);
            })
            .catch((error) => {
                console.log(error);
                //get data
                if (error.response) {
                    let data = error.response.data;
                    if (data.error) {
                        reject(data.error || data.reason);
                        return;
                    }
                }
                reject(error.message);
            });
    });
};

api.visit = function (path) {
    //urlencode
    path = encodeURIComponent(path);
    api.get("/api/user/visit/" + path);
}

export default api;