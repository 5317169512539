import React, { useEffect } from 'react';

export default function App() {
  const data = [
    {
      model: "Anime",
      prompt: "Prompt: masterpiece, best quality, winter, snow field, 1girl, bangs, blue eyes, blunt bangs, bonnet, brown footwear, brown hair, dress, frills, fruit, full body, hat, long hair, long sleeves, looking at viewer, pantyhose, purple dress, red flower, red rose, rose, shoes, sitting, solo, sky, sun, mountain, forest, lake",
      src: "/img/1.png"
    },
    {
      model: "Anime",
      prompt: "Botanical gardensvines, modernism, serenity and no one, hyper-realistic design, open view, digital, illustration, architecture,tranquility, beauty, comfort, rainny, night sky,cyberpunk, neon lights,neon signboard,lots of empire state building, Cumulonated pavement,Dimly lit alley",
      src: "/img/2.png"
    },
    {
      model: "Scene",
      prompt: "masterpiece, best quality, winter, snow field, 1girl, bangs, blue eyes, blunt bangs, bonnet, brown footwear, brown hair, dress, frills, fruit, full body, hat, long hair, long sleeves, looking at viewer, pantyhose, purple dress, red flower, red rose, rose, shoes, sitting, solo, sky, sun, mountain, forest, lake",
      src: "/img/3.png"
    },

    {
      model: "Scene",
      prompt: "masterpiece, best quality, 1girl, depth of field, holding, long sleeves, skirt, sky, street,rain,long hair,petals in the air,wind",
      src: "/img/4.png"
    },
    {
      model: "Scene",
      prompt: "Botanical gardens, stained glass windows, lots of greenery, vines, modernism, serenity and no one, hyper-realistic design, open view, interior design, digital, illustration, architecture, home, tranquility, beauty, comfort",
      src: "/img/5.png"
    },
    {
      model: "Scene",
      prompt: "Original Character, Volumetric Lighting, Best Shadows, Shallow Depth of Field, Portrait Of Stunningly Beautiful Girl, Petite, Delicate Beautiful Attractive Face With Alluring Yellow Eyes, Sharp Eyebrows, Expressionless, Blowing Bubble-Gum, Lovely Small Breasts, Layered Long Grey Streaks Hair, Blush Eyeshadow, Thick Eyelashes, Zipper hoodie Jacket, Wide Sleeve, Short Shorts, Hands In Pocket, Standing On Near Crowded Food Street In Night City, Neon Light Signboards, Food Court Outdoor, String Lights, (Highest Quality, Amazing Details:1.25), (Solo:1.3), Brilliant Colorful Paintings",
      src: "/img/6.png"
    },
    {
      model: "Scene",
      prompt: "city, day light, landscape",
      src: "/img/7.png"
    },
    {
      model: "Scene",
      prompt: "(masterpiece, best quality:1.2), ultra detailed, cinematic lighting, HDR, ilustration, landsape, sunrise, cherry blossom, impressive, chill, inspirational",
      src: "/img/8.png"
    },
    {
      model: "Scene",
      prompt: "(masterpiece, highest best quality, original, amazing, extremely, Ultra HD, incredibly absurdres, best quality, 8k), (open view), (very many hanging potted plants:1.8), (very many potted plant:1.8), (potted plants on the floor:1.8), (there are also potted plants on the cabinet:1.8), (botanical garden:1.5), (workshop), (dense bookcase:1.2), (blue sky, morning sun:1.5), (soft lighting:1.1), (very many glass windows:1.1), (light pours into the house through a large number of glass windows:1.2), Light blue tailored, depth of field, a lot of books, a detailed matte painting, wooden floor, many bookshelves, lots of plants and books on the shelves and a window, plant, potted_plant, rossdraws global illumination, box, clock, jar, palm_tree, plant, potted_plant, scenery, shelf, table, tree, Wide field of view, Japanese city, background is the street view of Tokoy, (modern city), (glass ceiling)",
      src: "/img/9.png"
    },
    {
      model: "Scene",
      prompt: "modernism, serenity and no one, hyper-realistic design, open view, digital, illustration, architecture,tranquility, beauty, comfort, day sky,cyberpunk, lots of empire state building, Cumulonated pavement, ((day light)), (((landscape))), cityscape, (((bird view)))",
      src: "/img/10.png"
    },
    {
      model: "Realistic",
      prompt: "awoman,complex 3d render ultra detailed, smile, portrait of beautiful woman, moody portrait, striking features, beauty, intricate details, dramatic composition, tension, contrast, texture, realism, high-quality rendering, stunning art, high quality, film grain,,swirly bokeh,(realistic, photo-realistic:1.2),physically-based rendering,(looking at viewer:1.4),(8k, best quality, masterpiece:1.2),(full body shot:1.1),octane render,extremely detailed CG, unity 8k wallpaper,in street,urban,city,(studio soft light,sunlight:1.2),(standing:1.1),(a girl is wearing sleeveless:1.5),hyper realistic detail shiny skin,ultra detailed,(ultra realistic:1.2),(intricate:1.2),(photorealistic:1.2),1girl,(skinny:1.3),detailed background",
      src: "/img/11.png"
    },

    {
      model: "Realistic",
      prompt: "city, day light, landscape",
      src: "/img/12.png"
    },
    {
      model: "Anime 2",
      prompt: "(masterpiece, best quality),1girl with long white hair sitting in a field of green plants and flowers, her hand under her chin, warm lighting, white dress, blurry foreground",
      src: "/img/13.png"
    }
  ]

  return (
    <div style={{ padding: "10pt" }}>
      {
        data.map((item, index) =>
          <div key={index}>
            <p>Model: {item.model}<br />Prompt: {item.prompt}</p>
            <img style={{ height: "200pt" }} src={item.src} />
            <hr />
          </div>
        )
      }
    </div>
  );
}