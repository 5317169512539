import React, { useEffect } from 'react';
import env from '../utils/env';
import { MDBFileUpload } from 'mdb-react-file-upload';
import {
  MDBCarousel,
  MDBCarouselItem,
  MDBBtn,
  MDBRow,
  MDBCol,
} from 'mdb-react-ui-kit';
import API from '../utils/api';

export default function App() {
  const subtitlecolor = "#444";

  return (
    <>
      <div style={{
        width: "100%",
        backgroundImage: `url("/bgmain.jpg")`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        paddingTop: "80pt",
        paddingBottom: "110pt"
      }}>
        <div style={{
          width: "100%",
          textAlign: "center",
          color: "black"
        }}>
          <h1 style={{ fontSize: "60pt", fontWeight: "bold" }}>One Stop AI Toolset</h1>
          <br />
          <h2>Powerful tools to create anything</h2>
          <h2>GPT, Image Creation, Text-to-speech...</h2>
          <br /><br /><br /><br />
          <MDBBtn id={"sendbtn"} color='dark' size="lg" style={{ textTransform: "none" }} onClick={window.showlogin}>Sign up for free</MDBBtn>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
          <MDBBtn id={"sendbtn"} color='white' size="lg" style={{ textTransform: "none" }} onClick={
            () => {
              window.location.href = "/freeimage"
            }
          }>Try image generation</MDBBtn>
        </div>
      </div>

      <div style={{
        width: "100%",
        paddingTop: "80pt",
        paddingBottom: "40pt"
      }}>
        <div style={{
          width: "100%",
          textAlign: "center",
          color: "black"
        }}>
          <h1 style={{ fontWeight: "bold" }}>NEW Decoration Generator</h1>
          <br /><br /><br />
          <img src="/deco.png" style={{ width: "60%" }} />
          <br /><br /><br /><br />
          <MDBBtn id={"sendbtn"} outline color='dark' size="lg" style={{ textTransform: "none" }} onClick={
            () => {
              window.location.href = "/freeimage"
            }
          }>Try image generation</MDBBtn>
          <br />
          <br />
          <p style={{ color: subtitlecolor }}>Select Interior from the model dropdown</p>
        </div>
      </div>

      <div style={{
        width: "100%",
        minHeight: "600pt",
        paddingTop: "80pt",
        paddingBottom: "80pt"
      }}>
        <div style={{
          width: "700pt",
          textAlign: "left",
          color: "black",
          margin: "auto",
          display: "block",
        }}>
          <MDBRow>
            <MDBCol size='12' sm='6'>
              <br />
              <h1 style={{ fontWeight: "bold" }}>Image creation</h1>
              <h5 style={{ color: subtitlecolor }}>with various models and customization.</h5>
              <br />
              <MDBBtn id={"sendbtn"} outline color='dark' size="lg" style={{ textTransform: "none" }} onClick={
                () => {
                  window.location.href = "/freeimage"
                }
              }>Try image generation</MDBBtn>
              <br />
              <br />
              <hr />
              <br />
              <h5 style={{ color: subtitlecolor }}>Character design</h5>
              <h5 style={{ color: subtitlecolor }}>Scene illustration</h5>
              <h5 style={{ color: subtitlecolor }}>Pose and outline reference</h5>
              <h5 style={{ color: subtitlecolor }}>Image denoise and enlarge</h5>
              <h5 style={{ color: subtitlecolor }}>Use your own LoRA</h5>
              <br />
              <MDBBtn id={"sendbtn"} outline color='dark' size="lg" style={{ textTransform: "none" }} onClick={
                () => {
                  window.location.href = "/services"
                }
              }>See more examples</MDBBtn>
            </MDBCol>
            <MDBCol size='12' sm='6'>
              <img src="/imagec.png" style={{ width: "100%" }} />
            </MDBCol>
          </MDBRow>
        </div>
      </div>
      <div style={{
        width: "100%",
        minHeight: "300pt",
        backgroundImage: `url("/bg2.jpg")`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        paddingTop: "80pt",
        paddingBottom: "80pt"
      }}>
        <div style={{
          width: "700pt",
          textAlign: "left",
          color: "black",
          margin: "auto",
          display: "block",
        }}>
          <MDBRow>
            <MDBCol size='12' sm='6'>
              <img src="/services.png" style={{ width: "80%" }} />
            </MDBCol>
            <MDBCol size='12' sm='6'>
              <br />
              <h1 style={{ fontWeight: "bold", }}>Popular tools</h1>
              <h5 style={{ color: subtitlecolor }}>to help you enlight and create.</h5>
              <br />
              <hr />
              <br />
              <h5 style={{ color: subtitlecolor }}>GPT with direct command and story manipulation</h5>
              <h5 style={{ color: subtitlecolor }}>TTS with 3 langauges and 10+ voices</h5>
              <br />
              <h5 style={{ fontSize: "14pt", color: "black" }}>Command: You are a story writer and writing a love novel. You need to assume user is a man and you are his girlfriend. You need to simulate the conversation between them.</h5>
              <h5 style={{ fontSize: "14pt", color: "green" }}>User: Hi, darling, how are you today?</h5>
              <h5 style={{ fontSize: "14pt", color: "blue" }}>Assistant: I'm doing well, thank you for asking. How about you? How was your day?</h5>
            </MDBCol>
          </MDBRow>
        </div>
      </div>

      <div style={{
        width: "100%",
        minHeight: "300pt",
        backgroundImage: `url("/bgmain.jpg")`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        paddingTop: "80pt",
        paddingBottom: "30pt"
      }}>
        <div style={{
          width: "100%",
          textAlign: "center",
          color: "black"
        }}>
          <h1 style={{ fontSize: "40pt", fontWeight: "bold" }}>Try today</h1>
          <br /><br />
          <MDBBtn id={"sendbtn"} color='dark' size="lg" style={{ textTransform: "none" }} onClick={window.showlogin}>Sign up for free</MDBBtn>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
          <MDBBtn id={"sendbtn"} color='white' size="lg" style={{ textTransform: "none" }} onClick={
            () => {
              window.location.href = "/freeimage"
            }
          }>Try image generation</MDBBtn>
        </div>
      </div>
    </>
  );
}