import { Web3ReactProvider } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import { Buffer } from 'buffer';
import Web3Login from './web3login';

window.Buffer = Buffer;

export default function App({ title, action, callback }) {
  const getLibrary = (provider) => {
    const library = new Web3Provider(provider, 'any');
    library.pollingInterval = 15000;
    return library;
  };

  return (
    <>
      <div className="text-center">
        <h5>{title}</h5>
        <Web3ReactProvider getLibrary={getLibrary}>
          <Web3Login callback={callback} />
        </Web3ReactProvider>
      </div>
    </>
  );
}